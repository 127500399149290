import React, { Component } from 'react'

import LevelIndicator from '../../components/LevelIndicator/LevelIndicator'

import './Game.scss'
import Timer from '../../components/Timer/Timer'
import AnswerButton from '../../components/AnswerButton/AnswerButton'
import Question from '../../components/Question/Question'
import { connect } from 'react-redux'
import { getGameQuestions } from '../../helpers/questions'
import { Redirect } from 'react-router-dom'
import { initNewGame, endGame, nextLevel } from '../../actions/index'
import Sounds from '../../helpers/sounds'
import { LAST_LEVEL_INDEX, MAX_ANSWER_TIME,  } from '../../constants/game-config'
import PhoneJoker from '../../components/Joker/PhoneJoker'
import AudienceJoker from '../../components/Joker/AudienceJoker'
import FiftyFiftyJoker from '../../components/Joker/FiftyFiftyJoker'

class Game extends Component {

    timer = null;

    constructor(props){
        super(props)
        this.state = {
            isLoggedIn: false,
            validatingQuestions: false,
            selectedAnswer: null,
            redirectToWinner: false,
            redirectToLooser: false,
            remainingSeconds: MAX_ANSWER_TIME,
            isQuestionFadedOut: true,
        }
    }

    componentDidMount(){

        this.initializeGame();

    }

    /**
     * Init new Game
     *
     * Load Questions for Game
     * Set Initial State
     * Restart Timer
     */
    initializeGame(){
        if(this.props.allQuestions.length === 0){
            return
        }
        const gameQuestions = getGameQuestions( this.props.allQuestions );

        console.log('StartGame', gameQuestions);
        this.props.initNewGame(gameQuestions);
        this.restartTimer()
        this.setState({
            isQuestionFadedOut: false
        })

        this.preloadQuestionImages(gameQuestions);

        this.playSound(Sounds.intro);

    }

    /**
     * Preload all images on Game Start for better performance and smother transtions between questions
     * @param {array} gameQuestions 
     */
    preloadQuestionImages( gameQuestions ){
        let images = [];
        for ( let i = 0; i < gameQuestions.length; i++) {
            images[i] = new Image()
            images[i].src = gameQuestions[i].question_image.url
        }
    }

    /**
     * Function called when user clicks a Answer
     *
     * Loggs in Question
     * Redirets user to winner/looser page
     * switches to next page
     *
     * @param {String} selectedAnswer
     */
    onSelectAnswer( selectedAnswer ){
        console.log('onselectanswer');
        const { gameQuestions, currentLevel } = this.props;
        // if question is already logged in, to nothing
        if(this.state.isLoggedIn || this.state.validatingQuestions) return;

        // window.navigator.vibrate([20, 2000, 200]);

        this.playSound(Sounds.loggedIn)

        this.setState({
            isLoggedIn: true,
            selectedAnswer: selectedAnswer,
        })

        /**
         * Show Validated Questions and play sound
         */
        setTimeout(() => {
            this.setState({
                validatingQuestions: true,
                isLoggedIn: false
            })

            // if correct answer, play levelUp Sound
            if( selectedAnswer === gameQuestions[currentLevel].correct_answer ){
                // window.navigator.vibrate(200);
                this.playSound(Sounds.levelUp)
            }

            // if wrong Answer, play wrongAnswer Sound
            if( selectedAnswer !== gameQuestions[currentLevel].correct_answer ){
                this.playSound(Sounds.wrongAnswer)
            }
        }, 1500);

        /**
         * Go to next question or Winner/Looser Page
         */
        setTimeout(() => {

            /**
             * If last level sucessfull, redirect to winner page
             */
            if( currentLevel === LAST_LEVEL_INDEX   && selectedAnswer === gameQuestions[currentLevel].correct_answer ){
                this.setState({redirectToWinner: true})
                return
            }

            /**
             * If answer is wrong, redirect to winner page
             */
            if( selectedAnswer !== gameQuestions[currentLevel].correct_answer ){
                this.setState({redirectToLooser: true})
                return
            }


            this.goToNextQuestion();

        }, 3500)

    }

    /**
     * Go to Next Question
     *
     * Load next Question
     * Restart timer
     */
    goToNextQuestion(){

        this.setState({
            isQuestionFadedOut: true
        })
        setTimeout(() => {
            /**
             * Go to next Level
             */
            this.props.nextLevel();
            this.setState({
                validatingQuestions: false,
                isLoggedIn: false
            })

        }, 500)

        setTimeout(() => {
            this.setState({
                isQuestionFadedOut: false
            })
            this.restartTimer()
        }, 500);
    }

    /**
     * Return class for Answer Components based on Question and current Game Status
     *
     * @param {String} answer
     */
    getValidatedQuestionClass(answer){

        const { gameQuestions, currentLevel } = this.props;

        if( this.state.isLoggedIn ){
            if( answer === this.state.selectedAnswer ) return 'loggedin';
        }
        if( this.state.validatingQuestions ){
            if( answer === gameQuestions[currentLevel].correct_answer ) return 'correct';
            if( answer === this.state.selectedAnswer ) return 'wrong';
        }

        return 'default'

    }

    /**
     * Restart the Timer
     *
     * Clear existing interval
     * Set new interval
     *
     */
    restartTimer(){
        clearInterval(this.timer);

        this.setState({remainingSeconds: MAX_ANSWER_TIME})

        this.timer = setInterval(() => {
            const { remainingSeconds, isLoggedIn, validatingQuestions} = this.state;
            if( isLoggedIn || validatingQuestions ) return;

            const newRemainingsSeconds = remainingSeconds - 1;
            if( remainingSeconds > 0 ){
                this.setState( { remainingSeconds: newRemainingsSeconds } );
            } else {
                this.setState({
                    redirectToLooser : true
                })
            }
        }, 1000);
    }

    playSound( func ){
        if(this.props.isSoundActivated){
            func()
        }
    }

    render() {

        const { remainingSeconds, isQuestionFadedOut } = this.state;
        const { gameQuestions, currentLevel } = this.props;
        const currentQuestion = gameQuestions[currentLevel];

        console.log('game hidden answers', this.props.hiddenAnswers);

        /**
         * Handle Redirects
         */
        if(this.props.allQuestions.length === 0){
            return <Redirect to="/"/>
        }
        if(this.state.redirectToLooser){
            return <Redirect to="/looser"/>
        }
        if(this.state.redirectToWinner){
            return <Redirect to="/winner"/>
        }

        return (
            <div className="Game">
                <div className={`QuestionScreen fade-out ${isQuestionFadedOut ? 'fade-out-active' : ''}`}>
                    <div className="timer-wrapper">
                        <Timer remainingSeconds={remainingSeconds}/>
                    </div>
                    <Question question={currentQuestion}/>
                    <div className="Jokers">
                        <PhoneJoker />
                        <FiftyFiftyJoker />
                        <AudienceJoker />
                    </div>

                </div>
                <div className={`Answers fade-out ${isQuestionFadedOut ? 'fade-out-active' : ''}`}>
                    {
                        currentQuestion && currentQuestion.allAnswers.map((answer, index) => {
                            return (
                                <AnswerButton
                                    index={ index }
                                    answer={ answer }
                                    key={ index }
                                    onClick={ () => this.onSelectAnswer( answer ) }
                                    status={ this.getValidatedQuestionClass( answer ) }
                                    isHiddenAnswer={ this.props.hiddenAnswers.includes( answer ) }
                                    />
                            )
                        })
                    }
                </div>
                <LevelIndicator activeLevel={currentLevel}></LevelIndicator>
            </div>
        )
    }
}



const mapStateToProps = state => {
    return {
        allQuestions: state.allQuestions,
        gameQuestions: state.gameQuestions,
        currentLevel: state.currentLevel,
        isSoundActivated: state.isSoundActivated,
        hiddenAnswers: state.hiddenAnswers
    };
};

function mapDispatchToProps(dispatch) {
    return {
        initNewGame: (questions) => dispatch(initNewGame(questions)),
        endGame: (questions) => dispatch(endGame(questions)),
        nextLevel: (data) => dispatch(nextLevel(data))
    };
}

const GameWithDispatch = connect(mapStateToProps, mapDispatchToProps)(Game);

export default GameWithDispatch;
