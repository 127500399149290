import {
    SET_QUESTIONS,
    SET_ALL_QUESTIONS,
    INIT_GAME,
    END_GAME,
    NEXT_QUESTION,
    TOGGLE_SOUND,
    USE_JOKER
} from '../constants/action-types'

export function setQuestions( payload ) {
    return { type: SET_QUESTIONS, payload }
};
export function setAllQuestions( payload ) {
    return { type: SET_ALL_QUESTIONS, payload }
};
export function initNewGame( payload ) {
    return { type: INIT_GAME, payload }
};
/**
 * 
 * @param {string} payload winner | looser
 */
export function endGame( payload ) {
    return { type: END_GAME, payload }
};
export function nextLevel( payload ) {
    return { type: NEXT_QUESTION, payload }
};
export function toggleSound(  ) {
    return { type: TOGGLE_SOUND }
};
export function useJoker( payload ) {
    console.log("useJoker", payload)
    return { type: USE_JOKER, payload }
};