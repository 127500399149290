import React from 'react'

import './IconButton.scss'

export default function IconButton( props ) {
    
    const {size, onClick, icon, color, status} = props;
    
    return (
        <div className={`IconButton IconName-${icon} size-${ size } color-${color} status-${status}`} onClick={onClick}>
            <img src={`img/${icon}-icon.svg`} alt={icon}/>
        </div>
    )
}
