import React from 'react';
import './App.scss';
import Header from './components/Header/Header'
import Start from './Pages/Start/Start';
import Game from './Pages/Game/Game';
import Instructions from './Pages/Instructions/Instructions';

import { AnimatedSwitch } from 'react-router-transition';

import {
  Route,
  HashRouter
} from "react-router-dom";

import Winner from './Pages/Winner/Winner';
import Looser from './Pages/Looser/Looser';
import StaticPage from './Pages/StaticPage/StaticPage';

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Header></Header>
        <AnimatedSwitch    
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 0 }}
          atActive={{ opacity: 1 }}
          className="switch-wrapper"
        >
          <Route path="/" exact>
            <Start />
          </Route>
          <Route path="/game" >
            <Game />
          </Route>
          <Route path="/winner" >
            <Winner></Winner>
          </Route>
          <Route path="/anleitung" >
            <Instructions></Instructions>
          </Route>
          <Route path="/looser" >
            <Looser />
          </Route>
          <Route path="/p/:pageSlug" component={StaticPage}>
          </Route>

        </AnimatedSwitch>
      </HashRouter>
    </div>
  );
}

export default App;
