import React from 'react'
import Joker from './Joker';
import { connect } from 'react-redux'
import { useJoker } from '../../actions/index'
import Sounds from '../../helpers/sounds';

// const answerPrefixes = ['A', 'B', 'C', 'D']

// const getCorrectAnswerPrefix = ( question ) => {
//     const correctAnswerIndex = question.allAnswers.indexOf(question.correct_answer);
//     return answerPrefixes[correctAnswerIndex];
// }

const AudienceJoker = ( props ) => {
    if( props.gameQuestions.length === 0) return null;
    
    const currentQuestion = props.gameQuestions[props.currentLevel];
    // const correctAnswerPrefix = getCorrectAnswerPrefix(currentQuestion);
    const isJokerAvailable = ! props.usedJokers.includes('audience');
    const isJokerActive = props.currentJokers.includes( 'audience' );

    const jokerStatus = isJokerActive ? 'active' : isJokerAvailable ? '' : 'disabled';

    const correctAnswerIndex = currentQuestion.allAnswers.indexOf(currentQuestion.correct_answer);

    const onUseJoker = () => {

        if(props.isSoundActivated) {
            Sounds.audienceJoker();
        }

        props.useJoker({
            usedJoker: 'audience'
        });
    }
    
    return (
        <Joker type="audience" status={jokerStatus} onUseJoker={() => onUseJoker()}>
            {/* <p className="description">Der Publikumsjoker tippt auf</p>
            <p className="answer">{correctAnswerPrefix}</p> */}
            <div className="audience-answers">
                <div className="answer-container">
                    <div className="rectangle answer" style={{ height: correctAnswerIndex === 0 ? '100px' : Math.floor( Math.random() * 60 ) + 1 + 'px' }}></div>
                    <p>A</p>
                </div>
                <div className="answer-container">
                    <div className="rectangle answer" style={{ height: correctAnswerIndex === 1 ? '100px' : Math.floor( Math.random() * 60 ) + 1  + 'px' }}></div>
                    <p>B</p>
                </div>
                <div className="answer-container">
                    <div className="rectangle answer" style={{ height: correctAnswerIndex === 2 ? '100px' : Math.floor( Math.random() * 60 ) + 1  + 'px' }}></div>
                    <p>C</p>
                </div>
                <div className="answer-container">
                    <div className="rectangle answer" style={{ height: correctAnswerIndex === 3 ? '100px' : Math.floor( Math.random() * 60 ) + 1  + 'px' }}></div>
                    <p>D</p>
                </div>
            </div>
        </Joker>
    )
}

const mapStateToProps = state => {
    return {
        gameQuestions: state.gameQuestions,
        currentLevel: state.currentLevel,
        usedJokers: state.usedJokers,
        currentJokers: state.currentJokers,
        isSoundActivated: state.isSoundActivated,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        useJoker: (payload) => dispatch(useJoker(payload))
    };
}

const AudienceJokerWithDispatch = connect(mapStateToProps, mapDispatchToProps)(AudienceJoker);

export default AudienceJokerWithDispatch;
