import React, { Component, Fragment } from 'react'
import IconButton from '../../components/IconButton/IconButton'
import { Link } from 'react-router-dom'

import './Start.scss'
import { ButtonLink } from '../../components/Button/Button'

import { connect } from 'react-redux'
import { setAllQuestions } from '../../actions/index'

import axios from 'axios';
import Footer from '../../components/Footer/Footer'


class Start extends Component {

    componentDidMount(){
        const { setAllQuestions } = this.props

        console.log('checkForLocalQuestion', window.localOfflineQuestions)
        const localQuestions = JSON.parse( localStorage.getItem('mrn-questions') );
        console.log({localQuestions});
        if( localQuestions ) setAllQuestions(localQuestions);

        if(window.localOfflineQuestions) {
            this.loadQuestionsFromLocalJson();
        } else {
            this.loadQuestionsFromAPI();
        }

        console.log('component mounted')
    }

    loadQuestionsFromAPI(){
        const { setAllQuestions } = this.props
        console.log('loading data from api');
        
        axios.get( process.env.REACT_APP_METROPOLISSIMO_QUESTIONS_ENDPOINT + 'wp/v2/mrn-questions/' )
            .then(res => {
                setAllQuestions(res.data);
                localStorage.setItem('mrn-questions', JSON.stringify(res.data));
            })
            .catch(err => {
                console.error('Error while loading questions from api', err)
            })

    }

    loadQuestionsFromLocalJson(){
        const { setAllQuestions } = this.props
        console.log('loading data from local json', window.localQuestions)
        
        setAllQuestions(window.localQuestions);

        // axios.get( './local-questions.json' )
        //     .then(res => {
        //         setAllQuestions(res.data);
        //     })
        //     .catch(err => {
        //         console.error('Error while loading questions from api', err)
        //     })

    }

    render() {
        const { allQuestionsLoaded } = this.props;

        return (
            <Fragment>
                <div className="StartPage">
                    <Link className="center" to="/game">
                        {
                            allQuestionsLoaded && (
                                <>
                                    <IconButton size="large" icon="play"/>
                                    <span className="start-game">Spiel Starten</span>
                                </>
                            )
                        }
                    </Link>
                    <div className="bottom">
                        <ButtonLink buttonStyle="white" to="/p/anleitung">Zur Spielanleitung</ButtonLink>
                    </div>
                    <img src="img/flag.svg" className="flag" alt="blue-styling-flag"/>
                </div>
                <Footer />
            </Fragment>
        )
    }
}


const mapStateToProps = state => {
    return {
        allQuestionsLoaded: state.allQuestionsLoaded
    };
  };
  
  function mapDispatchToProps(dispatch) {
    return {
        setAllQuestions: (questions) => dispatch(setAllQuestions(questions))
    };
  }
  
  const StartWithDispatch = connect(mapStateToProps, mapDispatchToProps)(Start);
  
  export default StartWithDispatch;