    import React from 'react'
import QuestionImage from '../QuestionImage/QuestionImage'

import './Question.scss'

export default function Question( props ) {

    const { question } = props;

    if(!question){
        return <div></div>
    }

    const { question_image: Image } = question;

    return (
        <div className="Question">
            <QuestionImage caption={question.question_image_captions} image={ Image.url }/>
            <h2>Was ist gesucht?</h2>
            <div dangerouslySetInnerHTML={{__html: question.title.rendered}} className="question-text">
            </div>
        </div>
    )
}
