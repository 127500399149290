import React from 'react'

import './ImageSourcesModal.scss'
import { connect } from 'react-redux';
import IconButton from '../IconButton/IconButton';

const ImageSourcesModal = (props) => {

    const { imageSource, onCloseModal } = props;
    return (
        <div className={`ImageSourcesModal`}>
            <IconButton icon="close" status="" onClick={onCloseModal}/>
            <h2>Bildquelle</h2>
            <ul>
                <li>
                    <strong>{ imageSource.imageSource }</strong><br />
                    <br />

                    {
                        imageSource.imageSourceUrl && (
                            <a href={imageSource.imageSourceUrl} target="_blank" rel="noopener noreferrer">{imageSource.imageSourceUrl}</a>
                        )
                    }
                    
                </li>
            </ul>
        </div>
    )
}


const mapStateToProps = state => {

    const { currentLevel, gameQuestions } = state
    const currentQuestion = gameQuestions[currentLevel];
    console.log({currentQuestion})

    const imageSource = {
        imageSource: currentQuestion.question_image_source,
        imageSourceUrl: currentQuestion.question_image_source_url,
        caption: currentQuestion.question_image_captions,
        question: currentQuestion.title.rendered
    }

    return {
        imageSource
    };
};

const ImageSourcesModalWithState = connect(mapStateToProps)(ImageSourcesModal);

export default ImageSourcesModalWithState;