/**
 * Get n number of elements from an array
 * 
 * @param {Array} arr 
 * @param {Number} n Number of elements to return
 */
const getRandomElementsFromArray = (arr, n) => {
    var result = new Array(n),
        len = arr.length,
        taken = new Array(len);
    if (n > len)
        throw new RangeError("getRandom: more elements taken than available", len);
    while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
}

const shuffle = ( array ) => {
    return array.sort(function() {
        return .5 - Math.random();
      });
}

/**
 * Get random sumber 
 * 
 * @param {Array} allQuestion 
 */
export const getGameQuestions = ( allQuestion ) => {
    console.log('gameQuestions', allQuestion);
    const level1Questions = getRandomElementsFromArray( shuffle(allQuestion).filter(question => question.question_level === "1" ), 5 )
    const level2Questions = getRandomElementsFromArray( shuffle(allQuestion).filter(question => question.question_level === "2" ), 5 )
    const level3Questions = getRandomElementsFromArray( shuffle(allQuestion).filter(question => question.question_level === "3" ), 5 )
    
    // put all questions for all levels together
    const allGameQuestions = [ ...level1Questions, ...level2Questions, ...level3Questions ]
    
    const allGameQuestionsWithRandomizedAnswers = allGameQuestions.map(question => {
        const wrongAnswers = question.wrong_answers.map(data => data.answer)
        question.allAnswers = shuffle([question.correct_answer, ...wrongAnswers])

        return question;
    })

    return allGameQuestionsWithRandomizedAnswers;

}