import React, { useEffect } from 'react'

import './Winner.scss'
import { ButtonLink as Button } from '../../components/Button/Button'
import ButtonSection from '../../components/ButtonSection/ButtonSection'
import ConfettiEffect from '../../components/ConfettiEffect/ConfettiEffect'
import Sounds from '../../helpers/sounds'

export default function Winner() {

    // Play Losser sound 
    useEffect(() => {
        Sounds.winner();
    }, []);


    return (
        <>
            <div className="Winner">
                <h1>Herzlichen Glückwunsch!</h1>
                <p>Sie haben alle Fragen richtig beantwortet! Wenn Sie möchten, versuchen Sie es erneut.</p>
                <ButtonSection>
                    <Button buttonStyle="secondary" to="/game">Neues Spiel starten</Button>
                    <Button buttonStyle="primary" to="/">Beenden</Button>
                </ButtonSection>
            </div>
            <ConfettiEffect />
        </>
    )
}
