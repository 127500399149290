import React from 'react'

import './AnswerButton.scss'

const prefixes = ['A', 'B', 'C', 'D'];

const answerPrefix = ( index ) => {
    return prefixes[index]
}

export default function AnswerButton( props ) {
    const { answer, status, index, onClick, isHiddenAnswer } = props;
    return (
        <button className={`AnswerButton ${ 'status-' + status } ${ isHiddenAnswer ? 'hidden' : ''}` } onClick={() => { ! isHiddenAnswer && onClick() } }>
            <span className="prefix">
                { answerPrefix( index ) }
            </span>
            <span>
                { answer }
            </span>
        </button>
    )
}
