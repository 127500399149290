import {
    SET_ALL_QUESTIONS,
    INIT_GAME,
    END_GAME,
    NEXT_QUESTION,
    TOGGLE_SOUND,
    USE_JOKER
} from '../constants/action-types'


const initialState = {
    allQuestions: [],
    allQuestionsLoaded: false,
    gameQuestions: [],
    isGameActive: false,
    currentLevel: 0,
    isWinner: false,
    isLooser: false,
    isSoundActivated: true,
    usedJokers: [],
    currentJokers: [],
    hiddenAnswers: []
};

function rootReducer(state = initialState, action) {

    
    if (action.type === SET_ALL_QUESTIONS) {
        return Object.assign({}, state, {
            // questions: state.questions.concat(action.payload)
            allQuestions: action.payload,
            allQuestionsLoaded: action.payload.length > 0
        });
    }
    if (action.type === INIT_GAME) {
        return Object.assign({}, state, {
            // questions: state.questions.concat(action.payload)
            gameQuestions: action.payload,
            isGameActive: true,
            currentLevel: 0,
            usedJokers: [],
            currentJokers: [],
            hiddenAnswers: []
        });
    }
    if (action.type === END_GAME) {
        return Object.assign({}, state, {
            // questions: state.questions.concat(action.payload)
            isGameActive: false,
            isLooser: action.payload === 'looser',
            isWinner: action.payload === 'winner'
        });
    }
    if (action.type === NEXT_QUESTION) {
        return Object.assign({}, state, {
            // questions: state.questions.concat(action.payload)
            currentLevel: state.currentLevel + 1,
            currentJokers: [],
            hiddenAnswers: [],
            usedJokers: state.currentJokers ? [ ...state.currentJokers, ...state.usedJokers ] : state.usedJokers
        });
    }
    if (action.type === TOGGLE_SOUND) {
        return Object.assign({}, state, {
            // questions: state.questions.concat(action.payload)
            isSoundActivated: ! state.isSoundActivated
        });
    }
    if (action.type === USE_JOKER) {
        console.log('action useJoker', action.payload )
        const { usedJoker, hiddenAnswers } = action.payload;
        return Object.assign({}, state, {
            // questions: state.questions.concat(action.payload)
            currentJokers: [...state.currentJokers, usedJoker],
            hiddenAnswers: hiddenAnswers ? hiddenAnswers : state.hiddenAnswers
        });
    }
    
    return state;
};
export default rootReducer;