import React from 'react'
import Joker from './Joker';
import { connect } from 'react-redux'
import { useJoker } from '../../actions/index'
import Sounds from '../../helpers/sounds';

const FiftyFiftyJoker = ( props ) => {
    if( props.gameQuestions.length === 0) return null;
    
    const currentQuestion = props.gameQuestions[props.currentLevel];
    const isJokerAvailable = ! props.usedJokers.includes('5050');
    const isJokerActive = props.currentJokers.includes( '5050' );


    const jokerStatus = isJokerActive ? 'active' : isJokerAvailable ? '' : 'disabled'


    const onUseJoker = () => {

        if(props.isSoundActivated) {
            Sounds.fiftyfiftyJoker();
        }


        // select two random wrong answers to filter out
        const wrongQuestionToShow = Math.floor(Math.random() * 2); // returns random number between 0 and 
        const questionsToHide = currentQuestion.wrong_answers.filter((value, index) => index !== wrongQuestionToShow );


        props.useJoker({
            usedJoker: '5050',
            hiddenAnswers: questionsToHide.map(element => element.answer)
        });
    }
    
    return (
        <Joker type="5050" status={jokerStatus} onUseJoker={() => onUseJoker()}></Joker>
    )
}

const mapStateToProps = state => {
    return {
        gameQuestions: state.gameQuestions,
        currentLevel: state.currentLevel,
        usedJokers: state.usedJokers,
        currentJokers: state.currentJokers,
        isSoundActivated: state.isSoundActivated,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        useJoker: (payload) => dispatch(useJoker(payload))
    };
}

const FiftyFiftyJokerWithDispatch = connect(mapStateToProps, mapDispatchToProps)(FiftyFiftyJoker);

export default FiftyFiftyJokerWithDispatch;
