import React, {useState, Fragment}from 'react'

import './QuestionImage.scss'
import ImageSourcesModal from '../ImageSourcesModal/ImageSourcesModal';

export default function QuestionImage( props ) {

    const {image, caption} = props;

    const [modalOpened, setModalOpened] = useState(false)

    return (
        <Fragment>
            <figure className="QuestionImage">
                <img src={image} alt={caption}/>
                <figcaption onClick={() => setModalOpened( ! modalOpened ) }>Bildquellen anzeigen</figcaption>
            </figure>
            {
                modalOpened && <ImageSourcesModal onCloseModal={() => setModalOpened(false)}/>
            }
        </Fragment>
    )
}
