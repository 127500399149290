import React from 'react'
import { Link } from 'react-router-dom'

import './Button.scss'

export default function Button(props) {

    const { buttonStyle, children } = props;

    return (
        <div className={`Button style-${buttonStyle}`}>
            { children }
        </div>
    )
}

export function ButtonLink( props ){
    const { to } = props;

    return (
        <Link to={ to }>
            <Button {...props}></Button>
        </Link>
    )
}