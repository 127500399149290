import React, { useEffect } from 'react';

import './Looser.scss'
import { ButtonLink as Button } from '../../components/Button/Button'
import ButtonSection from '../../components/ButtonSection/ButtonSection'
import { connect } from 'react-redux'
import Sounds from '../../helpers/sounds';

const Looser = ( props ) => {

    // Play Losser sound 
    useEffect(() => {
        Sounds.looser();
    }, []);

    return (
        <div className="Looser">
            {/* <h1>Schade …</h1>
            <p>… leider falsch. Vielleicht klappt es beim nächsten Mal. Versuchen Sie es einfach erneut!</p>
            <p>Erreichte Punktzahl: { props.currentLevel === 0 ? '0' : LEVEL_POINTS[props.currentLevel - 1] }</p> */}
            <ButtonSection>
                <Button buttonStyle="secondary" to="/game">Neues Spiel starten</Button>
                <Button buttonStyle="primary" to="/">Beenden</Button>
            </ButtonSection>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        currentLevel: state.currentLevel
    };
};

const LooserWithState = connect(mapStateToProps)(Looser);


export default LooserWithState