import React from 'react'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
 
export default () => {
  const { width, height } = useWindowSize()
  const numberOfPieces = width > 1920 ? 2000 : 1000;
  return (
    <Confetti
      width={width}
      height={height}
      numberOfPieces={numberOfPieces}
    />
  )
}