import React from 'react'

import './Footer.scss'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <div className="Footer">
            <div className="Footer--Inner">
                <ul className="nav">
                    <li>
                        <Link to="p/impressum">Impressum</Link>
                    </li>
                    <li>
                        <Link to="p/datenschutz">Datenschutz</Link>
                    </li>
                </ul>
            </div>
            
        </div>
    )
}
