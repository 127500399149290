import React from 'react'

import IconButton from '../IconButton/IconButton'
import IconDropdown from '../IconDropdown/IconDropdown'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { toggleSound } from '../../actions/index'


import './Header.scss'

function Header( props ) {

    const { isSoundActivated } = props;

    return (
        <header className="app-header">
            <div className="logo-wrapper">
                <Link to="/">
                    <img src="img/logo.svg" width="68px" alt="Logo der Metroppolregion Rhein Neckar"/>
                </Link>
            </div>
            <div className="content">
                <span>Metropolissimo</span><br />
                <span className="small">Das Rhein-Neckar-Quiz</span>
            </div>            
            <div>
                <IconDropdown>
                    <IconButton icon="sound" status={isSoundActivated ? '' : 'disabled'} onClick={() => props.toggleSound()}/>
                    <Link to="/">
                        <IconButton icon="quit"/>
                    </Link>
                </IconDropdown>
            </div>
        </header>
    )
}



const mapStateToProps = state => {
    return {
        isSoundActivated: state.isSoundActivated
    };
};
  
function mapDispatchToProps(dispatch) {
    return {
        toggleSound: () => dispatch(toggleSound())
    };
}
  
const HeaderDispatch = connect(mapStateToProps, mapDispatchToProps)(Header);

export default HeaderDispatch;