import React, { useEffect, useState } from 'react';

import './Instructions.scss'
import {  ButtonLink } from '../../components/Button/Button'
import axios from 'axios';
import { Link } from 'react-router-dom';

const Instructions = ( props ) => {

    const [instructions, setInstructions] = useState('');

    useEffect(() => {
         // Play Losser sound 
        axios.get( process.env.REACT_APP_METROPOLISSIMO_QUESTIONS_ENDPOINT + 'metropolissimo/v1/instructions' )
        .then(res => {
            const { instructions: newInstructions } = res.data
            console.log({newInstructions})
            setInstructions(newInstructions)
        })
        .catch(err => {

        })
        return () => {
            
        };
    })

    return (
        <div className="InstructionsPage">
            <Link to="/">Zurück zur Startseite</Link>
            <div dangerouslySetInnerHTML={{__html: instructions}}>
            </div>
            <div style={{textAlign: 'center', marginTop:"50px"}}>
                <ButtonLink to="/game" buttonStyle="primary">Spiel starten</ButtonLink>
            </div>
        </div>
    )
}

export default Instructions;
