import React from 'react'

import './Timer.scss'

export default function Timer( props ) {
    const { remainingSeconds } = props;

    const strokeDashArray = 376.991;
    const strokeDashArrayOffset = (strokeDashArray - strokeDashArray / 60 * remainingSeconds) * -1;

    const strokeColor = remainingSeconds > 30 ? '#009A7C' : remainingSeconds > 10 ? '#FFCC00' : '#F40000';

    // console.log('strokeDashArrayOffset', strokeDashArrayOffset)

    return (
        <div className="Timer">
            {remainingSeconds}
            <svg className="progress" width="120" height="120" viewBox="0 0 120 120">
                {/* <circle cx="60" cy="60" r="54" fill="none" stroke="#e6e6e6" stroke-width="12" /> */}
                <circle className="progress__value" cx="60" cy="60" r="60" fill="none" strokeWidth="12" stroke-alignment="inner" strokeDashoffset={strokeDashArrayOffset} stroke={strokeColor}/>
            </svg>
        </div>
    )
}
