import React, { Component } from 'react'
import IconButton from '../IconButton/IconButton'

import './IconDropdown.scss'

export default class IconDropdown extends Component {

    constructor(props){
        super(props)
        this.state = {
            isOpened : false
        }
    }

    render() {

        const { children } = this.props
        const { isOpened } = this.state

        return (
            <div className="IconDropdown">
                <div className="toggle">
                    <IconButton onClick={() => this.setState({ isOpened: ! isOpened })} icon="settings" color={isOpened ? 'secondary' : 'primary'}/>
                </div>
                <div className={`dropdown ${isOpened ? 'is-opened' : 'closed'}`}>
                    { children }
                    { isOpened }
                </div>
            </div>
        )
    }
}

