import React from 'react'
import Joker from './Joker';
import { connect } from 'react-redux'
import { useJoker } from '../../actions/index'
import Sounds from '../../helpers/sounds';

const answerPrefixes = ['A', 'B', 'C', 'D']

const getCorrectAnswerPrefix = ( question ) => {
    const correctAnswerIndex = question.allAnswers.indexOf(question.correct_answer);
    return answerPrefixes[correctAnswerIndex];
}

const PhoneJoker = ( props ) => {
    if( props.gameQuestions.length === 0) return null;
    
    const currentQuestion = props.gameQuestions[props.currentLevel];
    const correctAnswerPrefix = getCorrectAnswerPrefix(currentQuestion)
    const isJokerAvailable = ! props.usedJokers.includes('phone');
    const isJokerActive = props.currentJokers.includes( 'phone' );

    const jokerStatus = isJokerActive ? 'active' : isJokerAvailable ? '' : 'disabled'

    const onUseJoker = () => {

        if(props.isSoundActivated) {
            Sounds.phoneJoker();
        }

        props.useJoker({
            usedJoker: 'phone'
        });
    }
    
    return (
        <Joker type="phone" status={jokerStatus} onUseJoker={() => onUseJoker()}>
            <p className="description">Der Telefonjoker tippt auf</p>
            <p className="answer">{correctAnswerPrefix}</p>
        </Joker>
    )
}

const mapStateToProps = state => {
    return {
        gameQuestions: state.gameQuestions,
        currentLevel: state.currentLevel,
        usedJokers: state.usedJokers,
        currentJokers: state.currentJokers,
        isSoundActivated: state.isSoundActivated,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        useJoker: (payload) => dispatch(useJoker(payload))
    };
}

const PhoneJokerWithDispatch = connect(mapStateToProps, mapDispatchToProps)(PhoneJoker);

export default PhoneJokerWithDispatch;
