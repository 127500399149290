import React, { useState, useEffect }  from 'react';

import './StaticPage.scss'
import axios from 'axios';
import ButtonSection from '../../components/ButtonSection/ButtonSection';
import { ButtonLink } from '../../components/Button/Button';

const StaticPage = ( props ) => {

    const { pageSlug } = props.match.params;

    const [settings, setSettings] = useState({});

    useEffect(() => {

        axios.get( window.localOfflineQuestions ? './settings.json' :process.env.REACT_APP_METROPOLISSIMO_QUESTIONS_ENDPOINT + 'metropolissimo/v1/settings' )
            .then(res => {
                setSettings(res.data)
                console.log(res);
            })
            .catch(err => {
                console.error('Error while loading questions from api', err)
            })
    }, []);

    const pageContent = settings[pageSlug] ? settings[pageSlug] : '';

    return (
        <div className="StaticPage" >
            <div dangerouslySetInnerHTML={{__html:pageContent}}>

            </div>
            <br />
            {/* <h1>Schade …</h1>
            <p>… leider falsch. Vielleicht klappt es beim nächsten Mal. Versuchen Sie es einfach erneut!</p>
            <p>Erreichte Punktzahl: { props.currentLevel === 0 ? '0' : LEVEL_POINTS[props.currentLevel - 1] }</p> */}
            <ButtonSection>
                <ButtonLink buttonStyle="secondary" to="/game">Neues Spiel starten</ButtonLink>
            </ButtonSection>
        </div>
    )
}


export default StaticPage
