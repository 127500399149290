import React from 'react'

import './ButtonSection.scss';

export default function ButtonSection( props ) {
    return (
        <div className="ButtonSection">
            { props.children }
        </div>
    )
}
