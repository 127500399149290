import React, { useState } from 'react'
import IconButton from '../IconButton/IconButton'

import './Joker.scss'

export default function Joker( props ) {
    
    const { type, children, status, onUseJoker } = props;

    const [isOpened, setOpenedStatus] = useState(false);

    let buttonStatus;
    switch (status) {
        case 'disabled':
            buttonStatus = 'disabled'
            break;
        case 'active':
            buttonStatus = 'active'
            break;
    
        default:
            buttonStatus = ''
            break;
    }

    console.log({buttonStatus});

    return (
        <div className="Joker">
            <IconButton
                icon={type}
                onClick={ () => {
                    if( status !== 'disabled' && status !== 'active' ) {
                        onUseJoker()
                        setOpenedStatus(true)
                    }else if(status === 'active'){
                        console.log('toggle statzs');
                        setOpenedStatus(!isOpened)
                    }
                } }
                status={status}
                />
            {
                status === 'active' && isOpened && children && (
                    <div className={`speech-bubble`}>
                        <img src={`img/speech-bubble-upsidedown.svg`} alt={`speech-bubble`}/>
                        <div className="content">
                            {children}
                        </div>
                    </div>
                )
            }
        </div>
    )
}
