import React from 'react'

import './LevelIndicator.scss'

import { LEVEL_POINTS } from '../../constants/game-config'
/**
 * Format Nuber to string with thousand separator
 * @param {int} x
 */
const formatPoints = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export default function LevelIndicator( props ) {

    const { activeLevel } = props;

    return (
        <>
            <div className="LevelIndicatorMobile">
                <span className="Level">Level: {activeLevel + 1} von 15</span>
                <span className="Points">{ formatPoints( LEVEL_POINTS[activeLevel] ) }</span>
            </div>
            <div className="LevelIndicatorDesktop">
                {
                    LEVEL_POINTS.map((Points, LevelIndex) => {
                        return (
                            <div className={`Level ${activeLevel === LevelIndex ? 'is-active' : ''}`} key={LevelIndex}>
                                <span className="LevelIndex">{ LevelIndex + 1 }</span>
                                <span className="Points">{formatPoints(Points)}</span>
                            </div>
                        )
                    })
                }

            </div>
        </>
    )
}
