const playSound = ( soundfile ) => {
    var audio = new Audio(soundfile);
    const promise = audio.play();
    
    if (promise !== undefined) {

        promise.then(_ => {
    
        }).catch(error => {
        });
    
    }
}

const levelUp = () => {
    playSound('./sounds/level-up.mp3')
}

const loggedIn = () => {
    playSound('./sounds/loggedin.mp3')
}

const wrongAnswer = () => {
    playSound('./sounds/wrong.mp3')
}

const looser = () => {
    playSound('./sounds/looser.mp3')
}

const winner = () => {
    playSound('./sounds/winner.mp3')
}

const audienceJoker = () => {
    playSound('./sounds/joker-audience.mp3')
}

const phoneJoker = () => {
    playSound('./sounds/joker-phone.mp3')
}

const fiftyfiftyJoker = () => {
    playSound('./sounds/joker-fifty-fifty.mp3')

}
const intro = () => {
    playSound('./sounds/intro.mp3')
}

const Sounds = {
    levelUp,
    loggedIn,
    wrongAnswer,
    looser,
    winner,
    audienceJoker,
    phoneJoker,
    fiftyfiftyJoker,
    intro
}

export default Sounds